import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import DateRangePickerCustom from "../../../app/components/DateRangePickerCustom";
import api from "../../../api";
/* import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete"; */
/* import { useSelector } from "react-redux"; */
import { numberFormat } from "../../../functions";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CustomTable from "../../../app/components/CustomTable";
import { useSubheader } from "../../layout";
import { StatsSalesCompare } from "../widgets";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
/* const checkedIcon = <CheckBoxIcon fontSize="small" />; */

export default function SalesDashboardTeam( props ) {
  const suhbeader = useSubheader();
  const [rows, setRows] = useState( [] );
  const [insurancesTotal, setInsurancesTotal] = useState( 0 );
  const [contractsTotal, setContractsTotal] = useState( 0 );

  const [endDate, setEndDate] = useState( new Date() );
  const [startDate, setStartDate] = useState( moment().subtract( 7, "days" ) );

  const [activeTeams, setActiveTeams] = useState( [] );
  const [activeTeamsId, setActiveTeamsId] = useState( 0 );
  const [rangeViewMonth, setRangeViewMonth] = useState( false );
  const [amountGoals, setAmountGoals] = useState( { Goal1: 0, Goal2: 0 } );
  const [amountAccumulated, setAmountAccumulated] = useState( { Accumulated1: 0, Accumulated2: 0 } );

  /* const inputRef = useRef(null); */

  /* const { user } = useSelector((state) => state.auth); */

    const fetchActiveTeams = () => {
    api.get( "catalog/active-teams" )
      .then(
        (result) => {
          setActiveTeams( result.data.body );
        },
        (error) => {
          setActiveTeams( [] );
        }
      );
  }

  const fetchData = (start, end) => {
    setStartDate( start );
    setEndDate( end );

    setRangeViewMonth( new Date( start ).getMonth() === new Date( end ).getMonth() );

    let payload = {
      StartDate: moment( start ).format( "yyyy/MM/DD" ),
      EndDate: moment( end ).format( "yyyy/MM/DD" ),
      TeamId: 311
    }

    return api
      .get(`dashboard/get-insurance-contract`, {
        params: payload,
      })
      .then(
        (result) => {
          if (result.status === 201) {
            var data = result.data.body;
            setInsurancesTotal( result.data.body.InsurancesTotal );
            setContractsTotal( result.data.body.ContractsTotal );
            setRows( result.data.body.InsurancesContracts );
            setAmountGoals( { Goal1: data.GoalAmountReno, Goal2: data.GoalAmountPrevFin } );
            setAmountAccumulated( { Accumulated1: data.InsurancesMonth, Accumulated2: data.ContractsMonth } );
          }
        },
        (error) => {
          setInsurancesTotal( 0 );
          setContractsTotal( 0 );
          setRows( [] );
          console.log( error );
        }
      );
  }

  const handleActiveTeamsChange = (event, value) => {
    if (value !== null) {
      setActiveTeamsId( value.TeamId );

    } else {
      setActiveTeamsId( 0 );

    }
  }

  const handleCallback = async (start, end) => {
    await setStartDate( start );
    await setEndDate( end );
    await fetchData( start, end );
  }



  const handleTableChange = (action, tableState) => {
    let newInsuranceAmount = 0;
    let newContractAmount = 0;
    
    tableState.displayData.map( (item) => { 
                newInsuranceAmount += parseFloat( item.data[ 2 ].props.children.replace( "$", "" ).replaceAll( ",", "") );
                newContractAmount += parseFloat( item.data[ 3 ].props.children.replace( "$", "" ).replaceAll( ",", "") );
              } );

    /* setInsuranceDisplay( tableState.displayData.length ); */
    setInsurancesTotal( newInsuranceAmount );
    setContractsTotal( newContractAmount );
    /* console.log( "NUEVOS TOTALES --------->>>>>>", newInsuranceAmount, newContractAmount );
    console.log( "TABLA FILTRADA ------>>>>>>>>>", tableState ); */

  };




  useEffect( () => {
    fetchActiveTeams();
  }, [] );


  useEffect( () => {
    fetchData( startDate, endDate );
  }, [activeTeamsId] );

  /* const executeScroll = () => inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });    */

  const headRows = [
    { name: "SlpManager", label: "Gerente" },
    { name: "SlpName", label: "Asesor" },
    /* { name: "ManagerName", label: "Modo Seguro" }, */
    { name: "InsuranceAmount", label: "Renovaciones", numeric: true, options: {
      filter: false,
      customBodyRender: (value) => {
        return <b>{`${numberFormat( value )}`}</b>;
      },
      sort: true,
      sortCompare: (order) => {
        return ( obj1, obj2 ) => {
          let value1 = parseFloat( obj1.data );
          let value2 = parseFloat( obj2.data );

          return ( (value1 === value2) ? 0 : value1 < value2 ? -1 : 1 ) * (order === 'asc' ? 1 : -1);
        };
      }
    } },
    { name: "ContractAmount", label: "Previsión Final", numeric: true, options: {
      filter: false,
      customBodyRender: (value) => {
        return <b>{`${numberFormat( value )}`}</b>;
      },
      sort: true,
      sortCompare: (order) => {
        return ( obj1, obj2 ) => {
          let value1 = parseFloat( obj1.data );
          let value2 = parseFloat( obj2.data );

          return ( (value1 === value2) ? 0 : value1 < value2 ? -1 : 1 ) * (order === 'asc' ? 1 : -1);
        };
      }
    } },
    { name: "TotalAmount", label: "TOTAL", numeric: true, options: {
      /* style: {backgroundColor: "#C18EFF"}, */
      filter: false,
      customBodyRender: (value) => {
        return <b>{`${numberFormat( value )}`}</b>;
      },
      sort: true,
      sortCompare: (order) => {
        return ( obj1, obj2 ) => {
          let value1 = parseFloat( obj1.data );
          let value2 = parseFloat( obj2.data );

          return ( (value1 === value2) ? 0 : value1 < value2 ? -1 : 1 ) * (order === 'asc' ? 1 : -1);
        };
      }
    } },
  ];

  /* const getTrProps = (state, rowInfo, instance) => {
    console.log( "---------- ROWINFO >>>>>>>", rowInfo );
    if (rowInfo) {
        return {
            style: {
                'background-color': rowInfo.original.customercomplaints.order_ref === 1 ? '' : 'yellow',
            }
        }
    }
    return {};
}; */

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pt-5 bg-light">
          <div className="row align-items-center">
            <div className="col-12 mb-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Ventas por Equipo
                </span>
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              Rango de Fechas: {rangeViewMonth ? <br/> : <></>}
              <DateRangePickerCustom
                handleCallback={handleCallback}
                startDate={startDate}
                endDate={endDate}
                className="form-control mt-3 col-md-8"
              />
            </div>
            <div className="col-6">
{/*               <Autocomplete
                size="small"
                options={activeTeams}
                getOptionLabel={(option) => option.Name}
                onChange={handleActiveTeamsChange}                
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Equipo"
                    placeholder="Seleccione Equipo"
                  />
                )}
              /> */}



              {rangeViewMonth ? 
              (<>
              <div className="row" style={{ backgroundColor: "#F5B013", textAlign: "end" }}>
                <div className="col px-6 my-3">
                    <h4 className="card-title font-weight-bolder my-0">
                      META Renovaciones
                    </h4>

                    <span className="text-white font-weight-bolder font-size-h4">
                      {numberFormat( amountGoals.Goal1 )}
                    </span>
                  </div>
                  <div className="col px-6 my-3">
                    <h4 className="card-title font-weight-bolder my-0">
                      META Previsión Final
                    </h4>
                  
                    <span className="text-white font-weight-bolder font-size-h4">
                      {numberFormat( amountGoals.Goal2 )}
                    </span>
                </div>
              </div>
              <div className="row" style={{ backgroundColor: "#002060", textAlign: "center" }}>
                <div className="col px-6 my-5">
                    <h4 className="text-white card-title font-weight-bolder my-0">
                      Para alcanzar la META
                    </h4>

                    <span className="text-white font-weight-bolder font-size-h2">
                      {numberFormat( amountGoals.Goal1 - amountAccumulated.Accumulated1 )}
                    </span>
                  </div>
                  <div className="col px-6 my-5">
                    <h4 className="text-white card-title font-weight-bolder my-0">
                      Para alcanzar la META
                    </h4>
                  
                    <span className="text-white font-weight-bolder font-size-h2">
                      {numberFormat( amountGoals.Goal2 - amountAccumulated.Accumulated2 )}
                    </span>
                </div>
              </div>
              </>) 
              : (<></>)
              }






              <br></br><h3>Equipo: PROYECTO FELIX PARRA</h3>
            </div>
          </div>
        </div>
        
        <div className="card-body">


          {/* <div className="row">
            <div className="col-12 mb-5 rounded-xl py-5">
              <div className="col-md-6 col-lg-6 col-xxl-6 mb-5 bg-light-primary rounded-xl py-5">
                <StatsSalesCompare
                  amount={rows}
                  endDate={endDate}
                  startDate={startDate}
                />
              </div>
            </div>
          </div> */}




          <div className="row">
          <div className="col-12 mb-5  rounded-xl py-5">
              <div className="row m-0">
                <div className="col-md-12 px-6 py-2 rounded-xl">
                  <h2>Dashboard Ventas por Equipo</h2>
                </div>
              </div>
              <div className="row m-0" style={{ backgroundColor: "#F5B013" }}>
                <div className="col-md-12 px-6 py-2" style={{textAlign: "center"}}>
                  <h3 className="text-white ">{`Corte del ${moment( startDate ).format( "DD/MM/yyyy" )} al ${moment( endDate ).format( "DD/MM/yyyy" )}`}</h3>
                </div>
              </div>
              <div className="row m-0">
                <div className="col px-6 py-2" style={{ backgroundColor: "#002060" }}>
                  <h3 className="card-title text-white font-weight-bolder my-0">
                    Monto de ventas
                  </h3>
                </div>

                {/* <div className="col px-6 py-2 rounded-xl">
                  {/* <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span> * /}
                  <h4 className="card-title font-weight-bolder my-0">
                    Modo Seguro
                  </h4>
                  {/* <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span> * /}

                  <span className="text-white font-weight-bolder font-size-h3">
                    {/* <i className="flaticon-coins display-4 font-weight-bold"></i>{" "} * /}
                    {numberFormat((salesReport.SalesBBF && salesReport.SalesBBF.SalesContract) ? salesReport.SalesBBF.SalesContract : 0)}
                  </span>
                </div> */}
                <div className="col px-6 py-2" style={{ backgroundColor: "#002060", textAlign: "end" }}>
                  <h4 className="text-white card-title font-weight-bolder my-0">
                    Renovaciones
                  </h4>

                  <span className="text-white font-weight-bolder font-size-h3">
                    {numberFormat( insurancesTotal )}
                  </span>
                </div>
                <div className="col px-6 py-2" style={{ backgroundColor: "#002060", textAlign: "end" }}>
                  <h4 className="text-white card-title font-weight-bolder my-0">
                    Previsión Final
                  </h4>
                  
                  <span className="text-white font-weight-bolder font-size-h3">
                    {numberFormat( contractsTotal )}
                  </span>
                </div>

                <div className="col px-6 py-2" style={{ backgroundColor: "#002060", textAlign: "end" }}>
                  <h4 className="text-white card-title font-weight-bolder my-0">
                    TOTAL
                  </h4>

                  <span className="text-white font-weight-bolder font-size-h3">
                    {numberFormat( insurancesTotal + contractsTotal )}
                  </span>
                </div>

              </div>
              <hr></hr>

              <div className="row m-0">
                <div className="col px-12 py-2 rounded-xl">
                  <CustomTable
                    data={rows}
                    columns={headRows}
                    title={suhbeader.title}
                    actionAdd={false}
                    handleTableChange={handleTableChange}

                    /* setRowProps={ (row, dataIndex, rowIndex) => {
                        return {
                          style: { 
                            "background": (rowIndex % 2 === 0 ? '#002060' : '#f5f5f5'), 
                            "font": (rowIndex % 2 === 0 ? 'italic small-caps bold 12px/30px Georgia, serif' : ''),
                            "border": (rowIndex % 2 === 0 ? '1px solid #002060' : ''),
                            "color": (rowIndex % 2 === 0 ? 'F5B013' : '#002060')
                          
                          },
                        };
                      }
                    } */
              

                  />
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
